import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowUp } from '../../images/icons/arrowUp.svg';
import styles from './SearchHistory.module.scss';
import { FormattedMessage } from 'react-intl';
import { useSearchHistory } from '../../utils/useSearchHistory';

const SearchHistory = React.memo(({ onClose, onSelect, selected, isOpen }) => {
  const { searchHistory } = useSearchHistory();

  useEffect(() => {
    const handleClickOutside = () => {
      if (onClose) {
        onClose();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={classNames(styles.searchHistoryWrapper, { [styles.visible]: isOpen })}>
      <div className={styles.historyItemBox} data-testid="historyItemBox" tabIndex="0">
        {!!searchHistory?.length &&
          searchHistory.slice(0, 10).map((historyItem, index) => {
            return (
              <div
                className={classNames(styles.historyItemType, {
                  [styles.selected]: selected === index,
                })}
                key={index}
                data-testid={`historyItem${index}`}
              >
                <button
                  className={styles.link}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(historyItem);
                    }
                  }}
                >
                  <span className={styles.query}>{historyItem}</span>
                </button>
                {/* <span className={styles.historyItemName}>history item</span> */}
              </div>
            );
          })}
      </div>
      <div className={styles.closeSearchHistory}>
        <ArrowUp /> &nbsp; <FormattedMessage id="common.close" defaultMessage="close" />
      </div>
    </div>
  );
});

SearchHistory.displayName = 'SearchHistory';

export default SearchHistory;
